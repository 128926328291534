<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"
    (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)" (notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)" 
    ></app-header-global>
<div class="row allcontent">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA"
            [showamdoc]="showAmdoc"   [showEsep]="showesep" [showNDCBtn]="showndcBtn"
            ></app-side-overlay-menu>
    </div>
    <div class="col-sm-10">
        <div class="row empList">
            <div class="col-10">
                <!-- <h4>List of Initiated NDC Employees</h4> -->
            </div>
            <div class="col-2" style="text-align: right;">
                <!-- <a class="ndcBtn">NDC Section</a> -->
            </div>
        </div>
        <div class="col-sm-12">
            <div class="row profile-card" *ngIf="emppro">
                <div class="col paddings">
                    <div class="row">
                        <div class="col key">
                            Employee Name
                        </div>
                        <div class=" col-4 value">
                            {{emppro.name}}
                        </div>
                        <div class="col key">
                            Employee Code
                        </div>
                        <div class=" col-4 value">
                            {{emppro.emp_code |titlecase}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col key">
                            Designation
                        </div>
                        <div class=" col-4 value">
                            {{emppro.designation }}
                        </div>
                        <div class="col key">
                            Date of Relieving
                        </div>
                        <div class=" col-4 value">
                            {{emppro.date_of_relieving}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col key">
                            Personal Contact No
                        </div>
                        <div class=" col-4 value">
                            {{emppro.personal_contact_no}}
                        </div>
                        <div class="col key">
                            Personal E-Mail Id
                        </div>
                        <div class=" col-4 value">
                            {{emppro.persional_email_id}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col key">
                            Date of Joining
                        </div>
                        <div class=" col-4 value">
                            {{emppro.doj}}
                        </div>
                        <div class="col key">
                            Date of Resignation
                        </div>
                        <div class=" col-4 value">
                            {{emppro.dor}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col key">
                            Reporting Manager Name
                        </div>
                        <div class=" col-4 value">
                            {{emppro.manager}}
                        </div>
                        <div class="col key">
                            Entity
                        </div>
                        <div class=" col-4 value">
                            {{emppro.entity}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link" *ngFor="let section of sections; let isActive = first"
                    [class.active]="isActive" [id]="'nav-' + section + '-tab'" data-bs-toggle="tab"
                    [attr.data-bs-target]="'#nav-' + section" type="button" role="tab"
                    [attr.aria-controls]="'nav-' + section" [attr.aria-selected]="isActive ? 'true' : 'false'">
                    {{ section }} Section
                </button>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade" *ngFor="let section of sections; let isActive = first" [class.show]="isActive"
                [class.active]="isActive" [id]="'nav-' + section" role="tabpanel"
                [attr.aria-labelledby]="'nav-' + section + '-tab'">
               <!-- Check if the section has questions -->
                <div *ngIf="data.questions[section]; else noData">
                    <div *ngIf="isLoading">Loading questions...</div>
                    <table *ngIf="!isLoading">
                        <thead>
                            <tr>
                                <th class="sNum">S.No</th>
                                <th class="itemName">Item Name</th>
                                <th class="yesNo">Received (Yes/No/Na)</th>
                                <th class="mout">Amount</th>
                                <th class="remk">Remarks, if any</th>
                            </tr>
                        </thead>                      
                        <tbody>
                            <tr *ngFor="let item of data.questions[section]; let i = index">
                              <td>{{ item.order }}</td>
                              <td>{{ item.question }}</td>
                              <td>
                                <select [(ngModel)]="item.received" class="selectYN" (ngModelChange)="onSelectChange(item)">
                                  <option value="">Select</option>  <!-- Use empty string instead of null -->
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="NA">NA</option>
                                </select>
                              </td>
                              <td>
                                <input 
                                class="numberText" 
                                [(ngModel)]="item.amount" 
                                (keydown)="onKeyDown($event, item)" 
                                type="number" 
                                [disabled]="item.received === ''" />
                              
                              </td>
                              <td>
                                <textarea 
                                  class="remarks" 
                                  [(ngModel)]="item.remarks" 
                                  [disabled]="item.received === ''"></textarea>
                              </td>
                            </tr>
                          </tbody>
                    </table>
                    <!-- Submit Button -->
                    <div class="row empList">
                        <div class="col-10"></div>
                        <div class="col-2" style="text-align: right;">
                            <button class="submitBtn" (click)="handleSubmit(section)">Submit</button>
                        </div>
                    </div>
                </div>
                <ng-template #noData>
                    <p>No questions available for this section.</p>
                </ng-template>
            </div>
        </div>