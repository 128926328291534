import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmponboardingService {
  baseUrl = "https://staging-onboarding.amplussolar.com/onboarding";
  access_key: string = localStorage.getItem("accessToken");
  umail: string = localStorage.getItem("userName");
  apiUrl: any;
  prefix:any=this.baseUrl;
  headers: any = new HttpHeaders()
    .set("Authorization", `${this.access_key}`)
    .set("Content-Type", "application/json");

  requestOptions: any = { headers: this.headers };
  constructor(private http: HttpClient) {}
  getUser():Observable<any>{
    this.apiUrl = `${this.prefix}/get_all_profiles/?umail=${this.umail}`
    const url = this.apiUrl;
    return this.http.get(url,this.requestOptions);
  }
  reopenProfile(umail: string, email: string, remarks: string): Observable<any> {
    const url = `${this.prefix}/reopen_profile/`;
    const body = { umail, email, remarks };
    return this.http.post(url, body, this.requestOptions);
  }

  getProfile(umail: string, email: string): Observable<any> {
    const url = `${this.prefix}/get_profile/`;
    const body = { umail, email };
    return this.http.post(url, body, this.requestOptions);
  }

  approveProfile(umail: string, email: string): Observable<any> {
    const url = `${this.prefix}/approve_profile/`;
    const body = { umail, email };
    return this.http.post(url, body, this.requestOptions);
  }

//handle click
  createUser(umail: string, fname: string, email: string): Observable<any> {
    const url = `${this.prefix}/create_user/`;
    const body = { umail, fname, email };
    return this.http.post(url, body, this.requestOptions);
  }

  getEmpDefaultParams(email): Observable<any> {
    const url = `${this.prefix}/get-emp-defaults/?email=${email}`;
    return this.http.get(url, this.requestOptions);
  }

  addEmp(data: any): Observable<any> {
    const url = `${this.prefix}/add-emp/`;
    return this.http.post(url, data, this.requestOptions)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error.error_code || 'An unexpected error occurred.');
      })
    );
  }
  offrollAddEmp(data: any): Observable<any> {
    const url = `${this.prefix}/offrole_add-emp/`;
    return this.http.post(url, data, this.requestOptions)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.error.error_code || 'An unexpected error occurred.');
      })
    );
  }
}
