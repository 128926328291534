<span>
    <app-header-global></app-header-global>
</span>
<div class="main">
    <div class="content">
        <form [formGroup]="professionalInfoForm" class="personalInfo">

            <p class="upheading">Professional Information</p>

            <div class="border border-black p-3">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Employee's Details</span>
                    </div>
                </div>

                <div class="row">

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="">Employment Type</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="employment_type" class="infofield" disabled>
                                    <option value="">Select Employment Type</option>
                                    <option *ngFor="let type of employmentTypes" [value]="type[0]">{{ type[1] }}
                                    </option>
                                </select>
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('employment_type')?.errors?.['required'] && professionalInfoForm.get('employment_type')?.touched">
                                    Employment Type is required.
                                    </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="">Employment Category<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="employment_category" class="infofield" required>
                                    <option value="">Select Employment Category</option>
                                    <option *ngFor="let type of employmentCategory" [value]="type[0]">{{ type[1] }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('employment_category')?.errors?.['required']">
                                    Employment Type is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3">

                        <div class="row">
                            <div class="col-sm-12">
                                <label>Employee ID</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="emp_id" class="infofield" required
                                    placeholder="Employee ID">
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('emp_id')?.errors?.['required'] && professionalInfoForm.get('emp_id')?.touched">
                                    Employee ID is required.
                                </div>
                                <div class="erroMessage" *ngIf="empIdErrorMessage">
                                    {{empIdErrorMessage}}
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label for="jobTitleSelect">Job Title<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>

                            <div class="col-sm-12">
                                <select id="jobTitleSelect" class="infofield" formControlName="job_title">
                                    <option value="">Select Job Title</option>
                                    <option *ngFor="let title of jobTitles" [value]="title[0]">{{title[1]}}</option>
                                </select>
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('job_title')?.errors?.['required']">
                                    Job Title is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Functional Designation<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="functional_designation" class="infofield"
                                    placeholder="Functional Designation">
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('functional_designation')?.errors?.['required']">
                                    Functional Designation is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">E-Mail<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="email" formControlName="email" class="infofield" required
                                    placeholder="E-Mail">
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('email')?.errors?.['required']">
                                    E-Mail is required.
                                </div>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('email')?.errors?.['amplussolarEmail']">
                                    E-Mail must be in the format of &#64;amplussolar.com.
                                </div>
                                <div class="erroMessage" *ngIf="emailErrorMessage">
                                    {{emailErrorMessage}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Date of Birth -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Date of Joining<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">

                                <input id="doj" name="doj" required type="date" class="infofield"
                                    placeholder="Date of Joining" formControlName="doj" [min]="minDate"
                                    [max]="maxDate" />
                                <div class="erroMessage" *ngIf="professionalInfoForm.get('doj')?.errors?.['required']">
                                    Date of Joining is required.
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Gender -->

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Manager<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <div class="search-container">
                                    <ng-select class="custom" [items]="transformedManagers" bindLabel="name"
                                        formControlName="manager" placeholder="Select Manager" [searchable]="true"
                                        [multiple]="false">
                                    </ng-select>
                                </div>

                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('manager')?.errors?.['required']">
                                    Manager is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Company<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <div class="search-container">
                                    <ng-select class="custom" [items]="companies" bindLabel="name"
                                        formControlName="company" placeholder="Select Company" [searchable]="true"
                                        [multiple]="false">
                                    </ng-select>
                                </div>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('company')?.errors?.['required']">
                                    Company is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Business<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="business" class="infofield" required
                                    (change)="getDepartments()">
                                    <option value="">Select Business</option>
                                    <option *ngFor="let business of businesses" [value]="business.id">{{ business.name
                                        }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('business')?.errors?.['required']">
                                    Business is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Department<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <!-- professionalInfoForm.get('business')?.touched ||  -->
                            <div class="col-sm-12"
                                *ngIf="(professionalInfoForm.get('business')?.value == '' || professionalInfoForm.get('business')?.value == null)">
                                <select id="desigSelect" class="infofield" formControlName="department">
                                    <option value="">Select Department</option>
                                    <!-- <option *ngFor="let dept of departments " [value]="dept.id">{{dept.name}}</option> -->
                                </select>
                                <div class="erroMessage">
                                    Please Select Business.
                                </div>
                            </div>
                            <div class="col-sm-12"
                                *ngIf="!(professionalInfoForm.get('business')?.value == '' || professionalInfoForm.get('business')?.value == null)">
                                <select id="desigSelect" class="infofield" formControlName="department">
                                    <option value="">Select Department</option>
                                    <option *ngFor="let dept of departments " [value]="dept.id">{{dept.name}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('department')?.errors?.['required']">
                                    Department is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Aadhar -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Functional Department</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="functional_department" class="infofield"
                                    placeholder="Functional Department">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Physical Location<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="physical_location" class="infofield" required>
                                    <option value="">Select Physical Location</option>
                                    <option *ngFor="let location of physicalLocations" [value]="location[0]">{{
                                        location[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('physical_location')?.errors?.['required']">
                                    Physical location is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Hiring Type<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="hiring_type" class="infofield" required>
                                    <option value="">Select Hiring Type</option>
                                    <option *ngFor="let type of hiringTypes" [value]="type[0]">{{ type[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('hiring_type')?.errors?.['required']">
                                    Hiring Type is required.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <!-- Personal Information Section -->
            <p class="upheading">Personal Information</p>

            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Person's Details</span>
                    </div>
                </div>

                <div class="row">
                    <!-- Initials -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Initials<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="initials" class="infofield" required>
                                    <option value="">Select Initials</option>
                                    <option *ngFor="let initial of initials" [value]="initial">{{ initial }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('initials')?.errors?.['required']">
                                    Initials are required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- First Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>First Name<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="fname" class="infofield" placeholder="First Name"
                                    required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('fname')?.errors?.['required']">
                                    First Name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Middle Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Middle Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="mname" class="infofield" placeholder="Middle Name">
                            </div>
                        </div>
                    </div>
                    <!-- Last Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Last Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="lname" class="infofield" placeholder="Last Name"
                                    required>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <!-- Date of Birth -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Date of Birth<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input name="dob" required type="date" class="infofield" placeholder="Date of Birth"
                                    formControlName="dob" />
                                <div class="erroMessage" *ngIf="professionalInfoForm.get('dob')?.errors?.['required']">
                                    DOB is required.
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Gender -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Gender<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="gender" class="infofield" required>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('gender')?.errors?.['required']">
                                    Gender is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Marital Status -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Marital Status<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="marital_status" class="infofield" required>
                                    <option value="">Select Marital Status</option>
                                    <option *ngFor="let type of maritalCategory" [value]="type[0]">{{ type[1] }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('marital_status')?.errors?.['required']">
                                    Marital Status is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Father's Name<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input formControlName="father_name" placeholder="Father Name" name="father_name"
                                    class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('father_name')?.errors?.['required']">
                                    Father's Name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- Aadhar -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Aadhar Number<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="aadhar_no" class="infofield"
                                    placeholder="Aadhar Number" minlength="12" maxlength="12" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('aadhar_no')?.errors?.['required']">
                                    Aadhar Number is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">PAN Number<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="pan_no" class="infofield"
                                    placeholder="PAN Number (ex: ABCDE0000L)" minlength="10" maxlength="10" required>

                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('pan_no')?.errors?.['required']">
                                    PAN Number is required.
                                </div>

                            </div>
                        </div>
                    </div>



                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Spouse Name<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">

                                    <input type="text" formControlName="spouse_name" class="infofield"
                                        placeholder="Spouse Name" maxlength="100">

                                    <!-- <div class="erroMessage"
                                        *ngIf="professionalInfoForm.get('spouse_name')?.errors?.['pattern'] && professionalInfoForm.get('spouse_name')?.touched">
                                        Entern valid value
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Blood Group<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">

                                    <input type="text" formControlName="blood_group" class="infofield"
                                        placeholder="Blood Group" maxlength="4">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Previous Organisation<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">

                                    <input type="text" formControlName="prev_org" class="infofield"
                                        placeholder="Previous Organisation" maxlength="100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Address Details Section -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Address Details</span>
                    </div>
                </div>
                <div class="row">
                    <!-- City -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>City<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="city" name="city" placeholder="City"
                                    class="infofield" required>
                                <div class="erroMessage" *ngIf="professionalInfoForm.get('city')?.errors?.['required']">
                                    City is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Postal Code -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Postal Code<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="postal_code" name="postal_code"
                                    placeholder="Postal Code" class="infofield" minlength="6" maxlength="6"
                                    pattern="\d*" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('postal_code')?.errors?.['required']">
                                    Postal Code is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Address -->
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Correspondance Address<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="address" name="address" placeholder="Address"
                                    class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('address')?.errors?.['required']">
                                    Correspondance Address is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Permanent Address<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="permanent_address" name="address"
                                    placeholder="Address" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('permanent_address')?.errors?.['required']">
                                    Permanent Address is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Contact Details Section -->
            </div>
            <div class="border border-black p-3 mt-2">

                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Contact Details<span style="color: rgb(210, 54, 54);">*</span></span>
                    </div>
                </div>
                <div class="row">
                    <!-- Personal E-Mail Id -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Personal E-Mail Id<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="email" formControlName="personal_email" name="personal_email"
                                    placeholder="Personal E-Mail Id" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('personal_email')?.errors?.['required']">
                                    Personal Email Id is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Personal Contact No. -->
                    <div class="col-sm-3">
                        <div class="col-sm-12">
                            <label>Personal Contact No.<span style="color: rgb(210, 54, 54);">*</span></label>
                        </div>
                        <div class="col-sm-12">
                            <input type="text" formControlName="mobile_no" name="mobile_no"
                                placeholder="Personal Contact No." class="infofield" minlength="10" maxlength="10"
                                pattern="\d*" required>
                            <div class="erroMessage"
                                *ngIf="professionalInfoForm.get('mobile_no')?.errors?.['required']">
                                Personal Contact is required.
                            </div>

                        </div>
                    </div>
                    <!-- Alternate Contact No. -->
                    <div class="col-sm-3">
                        <div class="col-sm-12">
                            <label>Alternate Contact No.<span style="color: rgb(210, 54, 54);">*</span></label>
                        </div>
                        <div class="col-sm-12">
                            <input type="text" formControlName="alternate_mobile_no" name="alternate_mobile_no"
                                placeholder="Alternate Contact No." class="infofield" minlength="10" maxlength="10"
                                pattern="\d*">

                        </div>
                    </div>
                </div>
            </div>


            <!-- Qualification Details Section -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Qualification Details</span>
                    </div>
                </div>
                <div class="row">
                    <!-- Qualification (up to UG) -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Qualification (up to UG)</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="qualification_ug" name="qualification_ug"
                                    placeholder="Qualification (up to UG)" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <!-- Qualification (PG & above) -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Qualification (PG & above)</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="qualification_pg" name="qualification_pg"
                                    placeholder="Qualification (PG & above)" class="infofield">

                            </div>
                        </div>
                    </div>
                    <!-- Professional Experience -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Professional Experience<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="total_experience" name="total_experience"
                                    placeholder="Professional Experience" class="infofield" required>
                                    <div class="hint" style="margin-left: 11.8vw;">
                                        Hint: 3 Years 5 Months</div>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('total_experience')?.errors?.['required']">
                                    Professional Experience is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Solar Experience -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Solar Experience</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="solar_experience" name="solar_experience"
                                    placeholder="Solar Experience" class="infofield">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- bank details -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Bank Details</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Account Holder Name<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="account_holder_name" name="account_holder_name"
                                    placeholder="Account Holder Name" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('account_holder_name')?.errors?.['required']">
                                    Account Holder Name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Account Number<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="account_number" name="account_number"
                                    placeholder="Account Nmumber" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('account_number')?.errors?.['required']">
                                    Account Number is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Bank Name<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="bank_name" name="bank_name" placeholder="Bank Name"
                                    class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('bank_name')?.errors?.['required']">
                                    Bank Name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>IFSC Code<span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="ifsc_code" name="ifsc_code" placeholder="IFSC Code"
                                    class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('ifsc_code')?.errors?.['required']">
                                    IFSC Code is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">UAN Number <span style="color: rgb(210, 54, 54);">*</span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="uan_number" name="uan_number"
                                    placeholder="UAN Number" class="infofield">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-right">
                <!-- <button mat-button (click)="approveAll()" class="universalbtn" style="background-color: green;">Approve all
                    fields</button> -->
                    <!-- <button mat-button type="submit" class="universalbtn" style="cursor: not-allowed;"
                    *ngIf="!isValid()">Submit</button> -->
                <button mat-button type="submit" class="universalbtn"
                     (click)="onSubmit()">Submit</button>
            </div>
        </form>

    </div>

</div>