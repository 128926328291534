<div class=" side-menu" id="side_menu">
  <ul>
    <li (click)="allLink.home()" routerLink="/home" routerLinkActive="active"><img
        src="../../../assets/images/homeicon.svg" alt="">Home</li>
        <li routerLink="/profile" routerLinkActive="active">
          <img src="../../../assets/images/hrmanagericon.svg" alt=""> Profile
        </li>
    <li (click)="allLink.employeeDocuments()" routerLink="/employee-documents" routerLinkActive="active"><img
        src="../../../assets/images/documenticon.svg" alt="">Documents</li>
    <li (click)="allLink.amplusCircular()" routerLink="/circular" routerLinkActive="active"><img
        src="../../../assets/images/formsicon.svg" alt="">Circular / Forms</li>
    <li (click)="handBookPdf()"><img src="../../../assets/images/employeehandbook.svg" alt="">Employee Handbook</li>
    <li (click)="allLink.amplusMemories()" routerLink="/memories" routerLinkActive="active"><img
        src="../../../assets/images/memoriesicon.svg" alt="">Memories</li>
    <li (click)="allLink.amplusProduct()" routerLink="/products" routerLinkActive="active"><img
        src="../../../assets/images/productsicon.svg" alt="">Products</li>

  
    <span *ngIf="showpoa">
      <li (click)="allLink.amplusPoa()" routerLink="/poa" routerLinkActive="active"><img
          src="../../../assets/images/poaicon.svg" alt="">POA</li>
    </span>
    
    <span *ngIf="showHr">
      <li (click)="allLink.employeeOnboarding" routerLink="/hr-home" routerLinkActive="active"><img
          src="../../../assets/images/hrmanagericon.svg" alt="">Onboarding</li>
    </span>
    <!-- <span>
      <li (click)="allLink.amdoc()" routerLink="/template" routerLinkActive="active"><img
          src="../../../assets/images/poaicon.svg" alt="">AmDoc</li>
    </span> -->


    <div *ngIf="showamdoc">
      <li (click)="toggleSubmenu()" class="menu-item" [class.active]="isSubmenuVisible" [routerLinkActive]="['active']">
        <img src="../../../assets/images/amdoc.svg" alt=""> AmDoc
      </li>
    </div>
    <div>
      <ul *ngIf="isSubmenuVisible" class="submenu">
        <li *ngFor="let template of templateNames" routerLink="/template" (click)="logTemplateName(template)" class="service">
          {{ template.name }}
        </li>
      </ul>
      
    </div>
    

    <!-- routerLinkActive="active"  -->

   
  

    <span *ngIf='showClaim'>
      <li (click)="allLink.openExpenseManager()" routerLink="/expense-manager/all-claim" routerLinkActive="active"><img
          src="../../../assets/images/expensemanager.svg" alt="">Expense Manager</li>
    </span>
    
    <span *ngIf="showHr">
      <li (click)="allLink.openHrManager()" routerLink="/hr-manager" routerLinkActive="active"><img
          src="../../../assets/images/hrmanagericon.svg" alt="">HR Manager</li>
    </span>

 
    <li *ngIf="showEsep" routerLink="/e-separation" routerLinkActive="active">
      <img src="../../../assets/images/hrmanagericon.svg" alt="">E-Seperation
    </li>
    <li *ngIf="showNDCBtn" routerLink="/ndc" routerLinkActive="active">
      <img src="../../../assets/images/hrmanagericon.svg" alt="">NDC
    </li>

    <li (click)="allLink.logout()">
      <img src="../../../assets/images/logouticons.svg" alt="">Logout
    </li>
  </ul>
</div>

<div id="mySidenav" class="sidenav" style="display: none;">
  <p class="closebtn" (click)="allLink.closeNav()">&times;</p>


  <!-- <div class="mainNameSection">
    <span *ngIf="showText" class="round" id="round"> {{initials}}</span>
    <span *ngIf="!showText" class="round" id="roundImg"> </span> -->
  <!-- <span class="name">{{dates.name}} <br> <span class="job_title">{{dates.job_title}}</span></span> -->
  <!-- <span class="name">{{fullName}} <br> <span class="job_title">{{jobTitle}}</span></span>
  </div> -->

  <!-- <a href="javascript:void(0)" (click)="allLink.openLnd()"> <i class="fa fa-user" aria-hidden="true"></i> L&D
        Manager</a>
    <a href="javascript:void(0)" (click)="allLink.openAppraisal()"><i class="fa fa-th-large" aria-hidden="true"></i>
        Appraisal Portal</a>
    <a href="javascript:void(0)"> <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Documents</a>
    <a href="javascript:void(0)" (click)="allLink.logout()"> <i class="fa fa-power-off" aria-hidden="true"></i>
        Logout</a> -->

  <!-- side-overlay-menu.component.html -->
  <div class="side-menu">
    <ul>
      <!-- <li class="mainMenu" (click)="allLink.toggleSubMenu('menu1')" [class.active]="allLink.isSubMenuOpen('menu1')">
         <p><img src="../../../assets/images/01.svg"> Dashboard</p>
        </li>
        <ul class="subMenu" [hidden]="!allLink.isSubMenuOpen('menu1')">
          <li (click)="allLink.home()">Home</li>
          <li (click)="allLink.openLnd()">L&D Manager</li>
          <li (click)="allLink.openAppraisal()">Appraisal Portal</li>
          <li>GreytHR</li>
        </ul> -->

      <!-- <li class="mainMenu" (click)="allLink.toggleSubMenu('menu2')" [class.active]="allLink.isSubMenuOpen('menu2')">
        <p><img src="../../../assets/images/02.svg"> Documents</p>
    </li> -->


      <!-- <li class="mainMenu" (click)="allLink.home()">
        <p><img src="../../../assets/images/01.svg"> Dashboard</p>
      </li>
      <li class="mainMenu" (click)="allLink.openLnd()">
        <p><img src="../../../assets/images/L&D.svg"> L&D Manager</p>
      </li>
      <li class="mainMenu" (click)="allLink.openAppraisal()">
        <p><img src="../../../assets/images/Appraisal.svg"> Appraisal Portal</p>
      </li>
      <li class="mainMenu" (click)="allLink.opengreytHR()">
        <p><img src="../../../assets/images/GreytHR.svg"> GreytHR</p>
      </li>

      <li class="mainMenu" (click)="allLink.employeeDocuments()">
        <p><img src="../../../assets/images/02.svg"> Documents</p>
      </li>
      
      <span *ngIf="showClaim">
      <li class="mainMenu" (click)="allLink.openExpenseManager()">
        <p><img src="../../../assets/images/GreytHR.svg"> Expense Manager</p>
      </li>
       </span>
        <li class="mainMenu" (click)="allLink.openHrManager()">
        <p><img src="../../../assets/images/02.svg"> HR Manager</p>
      </li> -->

      <!-- <ul  class="subMenu" [hidden]="!allLink.isSubMenuOpen('menu2')">
          <li>Submenu 3</li>
          <li>Submenu 4</li>
        </ul> -->






      <!-- <li class="mainMenu" (click)="allLink.logout()">
        <p><img src="../../../assets/images/Logout.svg"> Logout</p>
      </li> -->
      <!-- Add more menu items and submenus as needed -->
    </ul>
  </div>



</div>
<div id="fullPageOverLay"></div>