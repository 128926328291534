<span>
    <app-header-global></app-header-global>
</span>
<div class="main">
    <!-- *ngIf="gotData && !user_exists;else usrexist" -->
    <div class="content">
        <form [formGroup]="professionalInfoForm" class="personalInfo">

            <p class="upheading">Professional Information</p>

            <div class="border border-black p-3">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Employee's Details</span>
                    </div>
                </div>

                <div class="row">

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="">Employment Type</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="employment_type" class="infofield" disabled>
                                    <option value="">Select Employment Type</option>
                                    <option *ngFor="let type of employmentTypes" [value]="type[0]">{{ type[1] }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">

                        <div class="row">
                            <div class="col-sm-12">
                                <label>Employee ID</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="emp_id" class="infofield" required
                                    placeholder="Employee ID" disabled>
                            </div>


                        </div>
                    </div>

                    <div class="col-sm-3">

                        <div class="row">
                            <div class="col-sm-12">
                                <label>Cris ID</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="sap_id" class="infofield" required
                                    placeholder="Cris ID" disabled>
                            </div>


                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>E-Mail</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="email" formControlName="email" class="infofield" required
                                    placeholder="E-Mail" disabled>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('email')?.errors?.['required']">
                                    E-Mail is required.
                                </div>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('email')?.errors?.['amplussolarEmail']">
                                    E-Mail must be in the format of &#64;amplussolar.com.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label for="jobTitleSelect" class="l1">Job Title</label>
                            </div>

                            <div class="col-sm-12">
                                <select id="jobTitleSelect" class="infofield" formControlName="job_title">
                                    <option value="">Select Job Title</option>
                                    <option *ngFor="let title of jobTitles" [value]="title[0]">{{title[1]}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('job_title')?.errors?.['required'])|| professionalInfoForm.get('job_title')?.value == '' || professionalInfoForm.get('job_title')?.value == null">
                                    Job Title is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Functional Designation</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="functional_designation" class="infofield"
                                    placeholder="Functional Designation">
                                <!-- <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('functional_designation')?.errors?.['required'] && professionalInfoForm.get('functional_designation')?.touched)|| professionalInfoForm.get('functional_designation')?.value == '' || professionalInfoForm.get('functional_designation')?.value == null">
                                    Functional Designation is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- Gender -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Physical Location</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="physical_location" class="infofield" required>
                                    <option value="">Select Physical Location</option>
                                    <option *ngFor="let location of physicalLocations" [value]="location[0]">{{
                                        location[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('physical_location')?.errors?.['required']) || professionalInfoForm.get('physical_location')?.value == '' || professionalInfoForm.get('physical_location')?.value == null">
                                    Physical location is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Manager</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="search-container">
                                    <ng-select class="custom" [items]="transformedManagers" bindLabel="name"
                                        formControlName="manager" placeholder="Select Manager" [searchable]="true"
                                        [multiple]="false">
                                    </ng-select>
                                </div>

                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('manager')?.errors?.['required']|| professionalInfoForm.get('manager')?.value == '' || professionalInfoForm.get('manager')?.value == null">
                                    Manager is required.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Company</label>
                            </div>
                            <div class="col-sm-12">
                                <!-- <select formControlName="company" class="infofield" required>
                                    <option value="">Select Company</option>
                                    <option *ngFor="let company of companies" [value]="company.company_id">{{
                                        company.name}}
                                    </option>
                                </select> -->
                                <div class="search-container">
                                    <ng-select class="custom" [items]="companies" bindLabel="name"
                                        formControlName="company" placeholder="Select Company" [searchable]="true"
                                        [multiple]="false">
                                    </ng-select>
                                </div>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('company')?.errors?.['required'])|| professionalInfoForm.get('company')?.value == '' || professionalInfoForm.get('company')?.value == null">
                                    Company is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Business</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="business" class="infofield" required
                                    (change)="getDepartments()">
                                    <option value="">Select Business</option>
                                    <option *ngFor="let business of businesses" [value]="business.id">{{ business.name
                                        }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('business')?.errors?.['required']) || professionalInfoForm.get('business')?.value == '' || professionalInfoForm.get('business')?.value == null">
                                    Business is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Department</label>
                            </div>
                            <!-- professionalInfoForm.get('business')?.touched ||  -->
                            <div class="col-sm-12"
                                *ngIf="(professionalInfoForm.get('business')?.value == '' || professionalInfoForm.get('business')?.value == null)">
                                <select id="desigSelect" class="infofield" formControlName="department">
                                    <option value="">Select Department</option>
                                    <!-- <option *ngFor="let dept of departments " [value]="dept.id">{{dept.name}}</option> -->
                                </select>
                                <div class="erroMessage">
                                    Please Select Business.
                                </div>
                            </div>
                            <div class="col-sm-12"
                                *ngIf="!(professionalInfoForm.get('business')?.value == '' || professionalInfoForm.get('business')?.value == null)">
                                <select id="desigSelect" class="infofield" formControlName="department">
                                    <option value="">Select Department</option>
                                    <option *ngFor="let dept of departments " [value]="dept.id">{{dept.name}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('department')?.errors?.['required'] || professionalInfoForm.get('department')?.value == '' || professionalInfoForm.get('department')?.value == null">
                                    Department is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-3">
                                <select
                                    *ngIf=" professionalInfoForm.get('business')?.touched || (professionalInfoForm.get('business')?.value == '' || professionalInfoForm.get('business')?.value == null); else showDept"
                                    id="desigSelect" class="infofield" formControlName="department" disabled>
                                    <option value="">Select Department</option>
                                    <option *ngFor="let dept of departments " [value]="dept.id">{{dept.name}}</option>
                                </select>
                                <select #showDept
                                id="desigSelect" class="infofield" formControlName="department">
                                <option value="">Select Department</option>
                                <option *ngFor="let dept of departments " [value]="dept.id">{{dept.name}}</option>
                            </select>
                                <div class="erroMessage" style="margin-left: 11.8vw;" 
                                    *ngIf="professionalInfoForm.get('business')?.value == '' || professionalInfoForm.get('business')?.value == null">
                                    Please Select Business.
                                </div>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('department')?.errors?.['required'] && professionalInfoForm.get('department')?.touched) || professionalInfoForm.get('department')?.value == '' || professionalInfoForm.get('department')?.value == null">
                                    Department is required.
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- Aadhar -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Functional Department</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="functional_department" class="infofield"
                                    placeholder="Functional Department">

                                <!-- <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('functional_department')?.errors?.['required'] && professionalInfoForm.get('functional_department')?.touched) || professionalInfoForm.get('functional_department')?.value == '' || professionalInfoForm.get('functional_department')?.value == null">
                                    Functional Department is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Hiring Type</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="hiring_type" class="infofield" required>
                                    <option value="">Select Hiring Type</option>
                                    <option *ngFor="let type of hiringTypes" [value]="type[0]">{{ type[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('hiring_type')?.errors?.['required']) || professionalInfoForm.get('hiring_type')?.value == '' || professionalInfoForm.get('hiring_type')?.value == null">
                                    Hiring Type is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Date of Joining</label>
                            </div>
                            <div class="col-sm-12">

                                <input id="doj" name="doj" required type="date" class="infofield"
                                    placeholder="Date of Joining" formControlName="doj" [min]="minDate"
                                    [max]="maxDate" />
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('doj')?.errors?.['required']) || professionalInfoForm.get('doj')?.value == '' || professionalInfoForm.get('doj')?.value == null">
                                    Date of Joining is required.

                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Last Promotion Date</label>
                            </div>
                            <div class="col-sm-12">

                                <input id="doj" name="doj" required type="date" class="infofield"
                                    placeholder="Last Promotion Date" formControlName="last_promotion_date"
                                    [min]="minDate" [max]="maxDate" disabled />

                            </div>
                        </div>
                    </div> -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Status <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="status" class="infofield" required>
                                    <option value="null">Status</option>
                                    <option *ngFor="let obj of statuses " [value]="obj[0]">{{obj[1]}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('status')?.errors?.['required']) || professionalInfoForm.get('status')?.value == null || professionalInfoForm.get('status')?.value == 'null' ">
                                    Status is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Paternity/Maternity Leave</label>
                            </div>
                            <div class="col-sm-12">

                                <input id="paternity_maternity_leave" name="paternity_maternity_leave" required
                                    type="number" class="infofield" placeholder="Paternity/Maternity Leave"
                                    formControlName="paternity_maternity_leave" [min]="0" [max]="365" />
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('last_promotion')?.errors?.['required'] && professionalInfoForm.get('last_promotion')?.touched">
                                    last_promotion is required.

                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Paternity/Maternity leave added on </label>
                            </div>
                            <div class="col-sm-12">

                                <input id="paternity_maternity_leave_added_on" name="paternity_maternity_leave_added_on" type="date" class="infofield"
                                    placeholder="Date of Joining" formControlName="paternity_maternity_leave_added_on" [min]="minDate"
                                    [max]="maxDate" />
                                <!-- <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('doj')?.errors?.['required']) || professionalInfoForm.get('doj')?.value == '' || professionalInfoForm.get('doj')?.value == null">
                                    Date of Joining is required.

                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <p class="upheading">Personal Information</p>

            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Person's Details</span>
                    </div>
                </div>

                <div class="row">
                    <!-- Initials -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Initials</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="initials" class="infofield" required>
                                    <option value="">Select Initials</option>
                                    <option *ngFor="let initial of initials " [value]="initial[0]">{{initial[1]}}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('initials')?.errors?.['required'])|| professionalInfoForm.get('initials')?.value == '' || professionalInfoForm.get('initials')?.value == null">
                                    First Name is required.</div>
                            </div>
                        </div>
                    </div>
                    <!-- First Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>First Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="fname" class="infofield" placeholder="First Name"
                                    required>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('fname')?.errors?.['required'])|| professionalInfoForm.get('fname')?.value == '' || professionalInfoForm.get('fname')?.value == null">
                                    First Name is required.</div>
                            </div>
                        </div>
                    </div>
                    <!-- Middle Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Middle Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="mname" class="infofield" placeholder="Middle Name">
                            </div>
                        </div>
                    </div>
                    <!-- Last Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Last Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="lname" class="infofield" placeholder="Last Name">
                                <!-- <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('lname')?.errors?.['required'] && professionalInfoForm.get('lname')?.touched)|| professionalInfoForm.get('lname')?.value == '' || professionalInfoForm.get('lname')?.value == null">
                                    Last Name is required.</div> -->
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <!-- Date of Birth -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Date of Birth</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">
                                    <input name="dob" required type="date" class="infofield" placeholder="Date of Birth"
                                        formControlName="dob" />
                                </div>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('dob')?.errors?.['required'])|| professionalInfoForm.get('dob')?.value == '' || professionalInfoForm.get('dob')?.value == null">
                                    DOB is required.</div>
                            </div>
                        </div>
                    </div>
                    <!-- Gender -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Gender</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="gender" class="infofield" required>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('gender')?.errors?.['required'])|| professionalInfoForm.get('gender')?.value == '' || professionalInfoForm.get('gender')?.value == null">
                                    Gender is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Personal Contact No.</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="mobile_no" name="mobile_no"
                                    placeholder="Personal Contact No." class="infofield" minlength="10" maxlength="10"
                                    (keydown)="validateNumber($event)" required>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('mobile_no')?.errors?.['required'])|| professionalInfoForm.get('mobile_no')?.value == '' || professionalInfoForm.get('mobile_no')?.value == null">
                                    Contact Number is required.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <!-- Qualification Details Section -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Qualification Details</span>
                    </div>
                </div>
                <div class="row">
                    <!-- Qualification (up to UG) -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Qualification (up to UG)</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="qualification_ug" name="qualification_ug"
                                    placeholder="Qualification (up to UG)" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('qualification_ug')?.errors?.['required'])|| professionalInfoForm.get('qualification_ug')?.value == '' || professionalInfoForm.get('qualification_ug')?.value == null">
                                    Qualification (up to UG) is required.</div>
                            </div>
                        </div>
                    </div>
                    <!-- Qualification (PG & above) -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Qualification (PG & above)</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="qualification_pg" name="qualification_pg"
                                    placeholder="Qualification (PG & above)" class="infofield">

                            </div>
                        </div>
                    </div>
                    <!-- Professional Experience -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Professional Experience</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="total_experience" name="total_experience"
                                    placeholder="Professional Experience" class="infofield" required>
                                <div class="hint" style="margin-left: 11.8vw;">
                                    Hint: 3 Years 5 Months</div>
                                <!-- <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('total_experience')?.errors?.['required'] && professionalInfoForm.get('total_experience')?.touched)|| professionalInfoForm.get('total_experience')?.value == '' || professionalInfoForm.get('total_experience')?.value == null">
                                    Total Experience is required.</div> -->
                            </div>
                        </div>
                    </div>
                    <!-- Solar Experience -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Solar Experience</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="solar_experience" name="solar_experience"
                                    placeholder="Solar Experience" class="infofield">
                                <div class="hint" style="margin-left: 11.8vw;">
                                    Hint: 3 Years 5 Months</div>
                                <!-- <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('solar_experience')?.errors?.['required'] && professionalInfoForm.get('solar_experience')?.touched)|| professionalInfoForm.get('solar_experience')?.value == '' || professionalInfoForm.get('solar_experience')?.value == null">
                                    Solar Experience is required.</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- bank details -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Exit Details</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">On Notice Period <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="notice_period" class="infofield" required>
                                    <option value="null">Select Notice Period</option>
                                    <option *ngFor="let type of notice" [value]="type.value">{{ type.key }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="(professionalInfoForm.get('notice_period')?.errors?.['required']) ||professionalInfoForm.get('notice_period')?.value == null">
                                    Notice Period is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Redirect required</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="redirect_required" class="infofield" required>
                                    <option value="null">Select</option>
                                    <option *ngFor="let item of redirect_req" [value]='item.value'>{{ item.key }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('redirect_required')?.errors?.['required']">
                                    Redirect is required to be selected.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3"
                        *ngIf="professionalInfoForm.get('redirect_required')?.value == 'true' || professionalInfoForm.get('redirect_required')?.value == true">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Redirect Employee</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="redirect_employee" class="infofield" required>
                                    <option value="">Select</option>
                                    <option *ngFor="let item of redirect_emails" [value]="item.emp_id">{{ item.email }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('redirect_employee')?.errors?.['required']">
                                    Redirect Employee is required to be selected.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="  professionalInfoForm.get('status')?.value == 1">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Exit Reason <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="exit_reason" class="infofield" required>
                                    <option value="">Select Exit Reason</option>
                                    <option *ngFor="let reason of exitReasons " [value]="reason[0]">{{reason[1]}}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('exit_reason')?.errors?.['required']">
                                    Exit Reason is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Exit Remarks <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="exit_remarks" name="exit_remarks"
                                    placeholder="Exit Remarks" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('exit_remarks')?.errors?.['required']">
                                    Exit Remarks is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Exit Date</label>
                            </div>
                            <div class="col-sm-12">

                                <input id="exit_date" name="exit_date" required type="date" class="infofield"
                                    placeholder="Exit Date" formControlName="exit_date" [min]="minDate"
                                    [max]="maxDate" />
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('exit_date')?.errors?.['required'] && professionalInfoForm.get('exit_date')?.touched">
                                    Exit Date is required.

                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="text-right">
            <!-- <button mat-button type="submit" *ngIf="!professionalInfoForm.valid" class="universalbtn" disabled (click)="onSubmit()">Submit {{professionalInfoForm.valid}}</button> -->
            <button mat-button type="submit" class="universalbtn" (click)="onSubmit()">Submit</button>
        </div>
    </div>


    <!-- <ng-template #usrexist>
        <div class="messagediv">
            <p>This user has been added to the  database.....</p> 
        </div>
     
    </ng-template> -->

</div>