<app-hr-header [employeeHeading]="headingEmployee"></app-hr-header>
<span class="search_box">
  <label for="search">Search</label>&nbsp;
  <input
    type="text"
    name="search"
    id="umail"
    [(ngModel)]="searchQuery"
    (input)="_onSearchInput($event)"
    placeholder="Search By Email"
  />
</span>

<div class="row">
  <div class="col">
    <button type="button" class="approvebtn" (click)="addEmployee()">Add Employee</button>
  </div>
</div>
<div class="table-responsive">
  <table class="table" id="emp_expense">
    <thead>
      <tr>
        <th>Employee ID</th>
        <th>Employee Name</th>
        <th>Email</th>
        <th>Gender</th>
        <th>Is Disabled?</th>
        <th></th>
        <!-- <th>Action</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let emp of employeedata; let i = index">
        <td>{{ emp.emp_id }}</td>
        <td>{{ emp.name | titlecase }}</td>
        <td>{{ emp.email }}</td>
        <td>{{ emp.gender | titlecase }}</td>
        <td>{{ is_Disabled(emp.is_disabled) }}</td>
        <!-- <td><button class="btn btn-info">Enable</button></td> -->
        <td style="color: #42478b; text-decoration: underline; cursor: pointer;" (click)="openUserProfile(emp)">
          view<img class="arrow-image" src="../../../assets/images/arrows.svg" alt="">
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="page_div">
  <div class="row" id="pagination-container">
    <div class="col-sm-5">
      Current Page {{ this.currentPage }} Total Number of Pages
      {{ this.totalPages }}
    </div>
    <div class="col-sm-7">

      <button
        (click)="previousPage()"
        class="btn btn-primary"
        [disabled]="this.currentPage === 1"
      >
        <<
      </button>
      <button
      (click)="firstPage()"
      class="btn btn-primary"
      [disabled]="this.currentPage === 1"
    >
      First
    </button>
      <ng-container *ngIf="totalPages > 0">
        <button
          *ngFor="let page of getNumericButtons()"
          (click)="gotoPage(page)"
          class="btn btn-primary"
          [class.active]="page === currentPage"
        >
          {{ page }}
        </button>
      </ng-container>
      <button
  (click)="lastPage()"
  class="btn btn-primary"
  [disabled]="this.currentPage === this.totalPages"
>
  Last
</button>
      <button
        (click)="nextPage()"
        class="btn btn-primary"
        [disabled]="this.currentPage === this.totalPages"
      >
        >>
      </button>
      
    </div>
  </div>
</div>