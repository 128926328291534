<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"
(notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)"  (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)" (notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)" 
></app-header-global>
<div class="row allcontent">
<div class="col-sm-2">
   <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA"
   [showamdoc]="showAmdoc"   [showEsep]="showesep" [showNDCBtn]="showndcBtn"
   ></app-side-overlay-menu>
</div>
<div class="col-sm-10">
   <div class="row empList">
      <div class="col-10">
         <h4>New E-Separation Requests</h4> 
      </div>
      <div class="col-2" style="text-align: right;">
         <!-- <a class="ndcBtn">NDC Section</a> -->
      </div>
   </div>
   <table class="employeeTable">
      <tr>
         <th>Employee Name</th>
         <th>Designation</th>
         <th>Department Manager</th>
         <th>Date of Joining</th>
         <th>Exit Date</th>
         <th>Status</th>
         <th>Action</th>
      </tr>
      <tr *ngFor="let item of teamData">
         <td>{{ item.employee_name }}</td>
         <td>{{ item.designation }}</td>
         <td>{{ item.manager }}</td>
         <td>{{ item.doj }}</td>
         <td>{{ item.exit_date }}</td>
         <td>

            <img src="{{ item.action_required ? '../../../assets/images/schedule.svg' : '../../../assets/images/lock.svg' }}">
            
            <button 
            [ngClass]="{
            'btn-success approved': item.current_status === 'Approved',
            'btn-warning pending': item.current_status === 'Pending',
            'btn-danger rejected': item.current_status === 'Rejected',
            'btn-danger withdraw': item.current_status === 'Withdraw'
            }" 
            [attr.disabled]="!item.action_required ? true : null"
            class="btn actionBtn">
            <ng-container [ngSwitch]="item.current_status">
               <span *ngSwitchCase="'Approved'">Approved</span>
               <span *ngSwitchCase="'Pending'">Pending by {{ item.current_approval_level }}</span>
               <span *ngSwitchCase="'Rejected'">Rejected by {{ item.current_approval_level }}</span>
               <span *ngSwitchCase="'Withdraw'">Withdraw</span>
            </ng-container>
            </button>
         </td>
         <!-- <td>{{ item.current_approval_level }}</td>
            <td>{{ item.action_required ? 'Yes' : 'No' }}</td> -->
         <td>
            <div class="dropdown">
               <a class="" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                 :
               </a>
               <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <!-- *ngIf="item.action_required" -->
                  <li *ngIf="item.action_required"><a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#e-Separation" href="#" (click)="getEmployeeExitDetails(item.exit_form_id)">View Request Details</a></li>
                  
                  <li><a (click)="getSeparationEmpRequestsDetails(item.employee_id)" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#employeeDetails" href="#">View Employee Details</a></li>

               </ul>
            </div>
         </td>
      </tr>
   </table>
</div>
<!-- Modal -->
<div class="modal fade" id="e-Separation" tabindex="-1" aria-labelledby="e-SeparationLabel" aria-hidden="true">
   <div class="modal-dialog modal-xl">
      <div class="modal-content">
         <div class="modal-header">
            <h1 class="modal-title fs-5" id="e-SeparationLabel">E - Separation</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body">
            <form [formGroup]="exitForm">
               <div class="row">
                  <div class="col-12">
                     <textarea required formControlName="exitReason" class="form-control" id="message-text"
                        rows="8" placeholder="Enter your reason here..." value="{{exitReason}}" disabled></textarea>
                  </div>
               </div>
               <div class="row userEnter">
                  <div class="col-6">
                     <label>WhatsApp No.</label>
                     <input formControlName="whatsAppNumber" type="text" class="form-control"
                        id="whatsApp-number" value="{{whatsappNo}}" disabled>
                  </div>
                  <div class="col-6">
                     <label>Add personal E-Mail Id</label>
                     <input formControlName="personalEmail" type="text" class="form-control"
                        id="personal-E-Mail" value="{{personalEmail}}" disabled>
                  </div>
               </div>
            </form>
         </div>
         <div class="modal-footer-e-Separation">
            <div class="row">
               <div class="col-7" style="font-weight: 500;">
                  <div class="row">
                     <div class="col-4">
                        <span class="exitDate">Exit Date : {{calculatedExitDate}}</span>
                     </div>
                     <div class="col-8">
                        Proposed exit date : <input value="{{calculatedExitDate}}" type="date" class="form-control" id="proExDate"  [(ngModel)]="proposedDate">
                     </div>
                  </div>
               </div>
               <div class="col-5 bottomBtn">
                  <button type="button" class="btn reject" data-bs-dismiss="modal" aria-label="Close" (click)="submitFormAcknowledge('Rejected' , proposedDate)">Reject</button>
                  <button type="button" class="btn acknowledge" data-bs-dismiss="modal" (click)="submitFormAcknowledge('Approved',proposedDate)">
                  Acknowledge
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div  class="modal fade" id="employeeDetails" tabindex="-1" aria-labelledby="employeeDetailsLabel" aria-hidden="true">
   <div class="modal-dialog modal-xl">
      <div class="modal-content">
         <div class="modal-header">
            <h1 class="modal-title fs-5" id="employeeDetailsLabel">Employee Details</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div class="modal-body">
            <div class="col-sm-12">


               <div class="row profile-card" *ngIf="empSeparation">
                  <div class="col paddings">
                     <div class="row">
                        <div class="col key">
                           Employee Name
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.name}}
                        </div>
                        <div class="col key">
                           Employee Code
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.emp_id |titlecase}}
                        </div>
                     </div>
                     <div class="row">
                        <div class="col key">
                           Designation
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.designation }}
                        </div>
                        <div class="col key">
                           Date of Relieving
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.relieving_date}}
                        </div>
                     </div>
                     <div class="row">
                        <div class="col key">
                           Personal Contact No
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.personal_contact_no}}
                        </div>
                        <div class="col key">
                           Personal E-Mail Id
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.persional_email_id}}
                        </div>
                     </div>
                     <div class="row">
                        <div class="col key">
                           Date of Joining
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.doj}}
                        </div>
                        <div class="col key">
                           Date of Resignation
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.resignation_date}}
                        </div>
                     </div>
                     <div class="row">
                        <div class="col key">
                           Reporting Manager Name
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.manager_name}}
                        </div>
                        <div class="col key">
                           Entity
                        </div>
                        <div class=" col-4 value">
                           {{empSeparation.entity}}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="promotionHistory">

            <!-- <div class="col-3 promotion" (click)="getPromotions(empSeparation.emp_id)">
               Promotion History
           </div> -->





           <div *ngIf="renderPromotion">
           
            <div class="modal-header">
               <h1 class="modal-title fs-5" id="employeeDetailsLabel"> Promotion History</h1>
              
            </div>
            


            <div class="">
               <div class="manage-proposal-wrapper" style="width: 600px; max-height: fit-content; overflow-x: hidden !important;">
                 <div class="row">
                   
                   <div class="col heading">
                     <font color="#555">{{promotionData[0].id}}:&nbsp;{{promotionData[0].employee_fullname}}</font>
                   </div>
                 </div>
                 <div *ngIf="promotionData.length != 0" style="overflow-x: hidden;">
                   <div *ngFor="let item of promotionData">
                     <div class="row">
                       <div class="col-3 cycle">{{item.appraisal_cycle.name}}</div>
                       <div class="col-1">
                         <i class="fa fa-dot-circle-o dot" aria-hidden="true"></i>
                       </div>
                       <div class="col history">
                         <font color="#999999">{{item.old_designation}}</font>&nbsp;<i class="fa fa-angle-double-right arrow" aria-hidden="true"></i>&nbsp;{{item.new_designation}}
                       </div>
                     </div>
                     <div class="vertical"></div>
                   </div>
                 </div>
                 <div class="row" *ngIf="promotionData.length == 0">
                   <div class="col no-promotion">No Promotions Yet :\</div>
                 </div>
               </div>
               <div class="modal-footer"></div>
             </div>
           </div>
       

         <!-- <div class="modal-footer">

           
             
            </div> -->
         </div>
      </div>
   </div>
</div>