import { Component, Inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmponboardingService } from '../../employee-onboarding/services/emponboarding.service';
import { HrserviceService } from '../../hr-manager/service/hrservice.service';
import { ToastrService } from 'ngx-toastr';
import { ActionPopupComponent } from '../../action/action.component';

function amplussolarEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const email = control.value;
        if (email && !email.endsWith('@amplussolar.com')) {
            // If the email does not end with @amplussolar.com
            return { amplussolarEmail: true };
        }
        return null; // If the email is valid or the control is empty
    };
}

@Component({
    selector: 'app-add-emp',
    templateUrl: './add-employee.component.html',
    styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {
    fb = new FormBuilder();
    departments: any;
    companies: any;
    managers: any;
    statuses: any;
    employmentTypes: any;
    hiringTypes: any;
    physicalLocations: any;
    businesses: any;
    umail: any;
    email: any;
    personalInfoForm: FormGroup;
    professionalInfoForm: FormGroup;
    initials = ['Mr', 'Ms', 'Mrs'];
    dialogRef: any;
    gotData: boolean = false;
    minDate: any;
    maxDate: any;
    intern_emp_id: any;
    off_roll_emp_id: any;
    jobTitles: any;
    transformedManagers: any;
    user_exists: boolean = true;
    emailErrorMessage: string | null = null;
    empIdErrorMessage: string | null = null;
    errorMessage: string | null = null;
    employmentCategory = [
        [
            "Off-Roll",
            "Off-Roll"
        ],
        [
            "Intern",
            "Intern"
        ]
    ];
    maritalCategory = [
        [
            "Single",
            "Single"
        ],
        [
            "Maried",
            "Maried"
        ]
    ];


    constructor(private http: HttpClient,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private hrService: HrserviceService,
        private toast: ToastrService,
        private empOnboardingService: EmponboardingService
    ) {

        this.professionalInfoForm = this.fb.group({
            initials: ['', Validators.required],
            fname: ['', Validators.required],
            mname: [''],
            lname: [''],
            dob: ['', Validators.required],
            gender: ['', Validators.required],
            marital_status: ['', Validators.required],
            father_name: [''],
            aadhar_no: ['', Validators.required],
            pan_no: ['', Validators.required],
            spouse_name: [''],
            blood_group: [''],
            prev_org: [''],
            city: ['', Validators.required],
            postal_code: ['', Validators.required],
            address: ['', Validators.required],
            permanent_address: ['', Validators.required],
            personal_email: ['', Validators.required],
            mobile_no: ['', Validators.required],
            alternate_mobile_no: [''],
            qualification_ug: [''],
            qualification_pg: [''],
            total_experience: ['', Validators.required],
            solar_experience: [''],
            account_holder_name: ['', Validators.required],
            account_number: ['', Validators.required],
            bank_name: ['', Validators.required],
            ifsc_code: ['', Validators.required],
            employment_type: ['Off-Roll'],
            employment_category: ['', Validators.required],
            emp_id: [''],
            job_title: [''],
            functional_designation: ['', Validators.required],
            uan_number: [''],
            doj: [null, Validators.required],
            physical_location: ['', Validators.required],
            manager: [null, Validators.required],
            department: ['', Validators.required], //get from employee table
            functional_department: [''],
            business: ['', Validators.required],
            company: [null, Validators.required],
            hiring_type: ['', Validators.required],
            status: [0],
            email: ["", [Validators.required, amplussolarEmailValidator()]],
        })


    }


    ngOnInit() {

        this.professionalInfoForm.get('employment_category')?.valueChanges.subscribe((value) => {
            if (value === 'Intern') {
                this.professionalInfoForm.get('emp_id')?.setValue(this.intern_emp_id);
            } else {
                this.professionalInfoForm.get('emp_id')?.setValue(this.off_roll_emp_id);
            }
        });
        this.umail = this.route.snapshot.queryParamMap.get('umail');
        // this.email = this.route.snapshot.queryParamMap.get('email');

        this.hrService.getCompanies().subscribe((res: any) => {
            this.companies = res.data.companies;
            console.log(this.companies)
        })

        this.hrService.getDepartments().subscribe((res: any) => {
            this.businesses = res.data;
        })
        this.empOnboardingService.getEmpDefaultParams(this.email).subscribe((response: any) => {
            this.jobTitles = response.jobTitles;
            this.departments = response.depts;
            // this.companies = response.companies;
            this.managers = response.managers;
            this.transformedManagers = this.managers.map((manager: any[]) => ({
                id: manager[0],
                name: manager[1]
            }));

            // this.businesses = response.businesses;
            let year = parseInt(response.date.slice(0, 4));
            let month = (response.date.slice(5, 7))
            let day = (response.date.slice(8, 10))
            this.minDate = `${year - 1}-${month}-${day}`
            this.intern_emp_id = response.intern_emp_id;
            this.off_roll_emp_id = response.off_roll_emp_id;
            this.maxDate = `${year + 1}-${month}-${day}`
            this.statuses = response.statuses;
            this.employmentTypes = response.employmentTypes;
            this.hiringTypes = response.hiringTypes;
            this.physicalLocations = response.physicalLocations;
            this.user_exists = response.user_exists;
            this.professionalInfoForm.get('emp_id')?.disable();
            this.gotData = true;
            (error: any) => {
                console.log(error);
            }
            // })
        })


    }
    getDepartments() {
        this.professionalInfoForm.value.department = null;
        this.departments = [];
        console.log(this.professionalInfoForm.value.business);
        console.log("in get departments");
        console.log(this.businesses)
        let businessId;
        businessId = this.professionalInfoForm.value.business;

        for (var i = 0; i < this.businesses.length; i++) {
            if (this.businesses[i].id == businessId) {
                this.departments = this.businesses[i].departments;
                console.log(this.departments, '----departments');
            }
        }
        console.log(this.departments, '----departments')
    }

    checkSolarExperience() {

        let formVal = this.professionalInfoForm.value.solar_experience;
        const res: string = formVal;
        console.log(res);
        const str: string[] = res.split(" ");
        console.log(str);

        if (res == '' || res == null) {
            return true;
        }
        else if (str.length > 0 && str.length <= 2) {
            if (Number(str[0]) <= 0 && Number(str[0]) > 60) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
            else if (Number(str[0]) % 1 != 0) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
            else if (Number(str[2]) % 1 != 0) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
            else if (str[1] == 'Years' || str[1] == 'years' || str[1] == 'Year' || str[1] == 'year') {
                // this.toast.error("Please add experience in 'YY Years MM Months' format");
                return true;
            }
            else {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
        }

        else if (str.length == 3) {
            this.toast.error("Please add experience in 'YY Years MM Months' format");
            return false;
        }

        else if (str.length > 2 && str.length < 5) {

            if (Number(str[2]) >= 0 && Number(str[2]) <= 12) {

                if (Number(str[2]) % 1 != 0) {
                    this.toast.error("Please add experience in 'YY Years MM Months' format");
                    return false;
                }
                else if (Number(str[0]) % 1 != 0) {
                    this.toast.error("Please add experience in 'YY Years MM Months' format");
                    return false;
                }
                else if (str[3] == 'Months' || str[3] == 'months' || str[3] == 'Month' || str[3] == 'month') {

                    return true;
                }
                else {
                    this.toast.error("Please add experience in 'YY Years MM Months' format");
                    return false;
                }
            }
            else if (Number(str[2]) < 0) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
            else if (Number(str[2]) > 12) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }


        }
        else {
            this.toast.error("Please add experience in 'YY Years MM Months' format");
            return false;
        }

    }


    checkTotalExperience() {

        let formVal = this.professionalInfoForm.value.total_experience;

        const res: string = formVal;
        console.log(res);
        const str: string[] = res.split(" ");
        console.log(str);

        if (res == '' || res == null) {
            return false;
        }
        else if (str.length > 0 && str.length <= 2) {
            if (Number(str[0]) <= 0 && Number(str[0]) > 60) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
            else if (Number(str[0]) % 1 != 0) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
            else if (Number(str[2]) % 1 != 0) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
            else if (str[1] == 'Years' || str[1] == 'years' || str[1] == 'Year' || str[1] == 'year') {
                // this.toast.error("Please add experience in 'YY Years MM Months' format");
                return true;
            }
            else {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
        }

        else if (str.length == 3) {
            this.toast.error("Please add experience in 'YY Years MM Months' format");
            return false;
        }

        else if (str.length > 2 && str.length < 5) {

            if (Number(str[2]) >= 0 && Number(str[2]) <= 12) {

                if (Number(str[2]) % 1 != 0) {
                    this.toast.error("Please add experience in 'YY Years MM Months' format");
                    return false;
                }

                else if (Number(str[0]) % 1 != 0) {
                    this.toast.error("Please add experience in 'YY Years MM Months' format");
                    return false;
                }

                else if (str[3] == 'Months' || str[3] == 'months' || str[3] == 'Month' || str[3] == 'month') {

                    return true;
                }
                else {
                    this.toast.error("Please add experience in 'YY Years MM Months' format");
                    return false;
                }
            }
            else if (Number(str[2]) < 0) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }
            else if (Number(str[2]) > 12) {
                this.toast.error("Please add experience in 'YY Years MM Months' format");
                return false;
            }


        }
        else {
            this.toast.error("Please add experience in 'YY Years MM Months' format");
            return false;
        }
    }

    isValid() {
        let formvalue = this.professionalInfoForm.value;
        if (formvalue.mobile_no == '' || formvalue.mobile_no == null) {
            this.toast.error("Please enter Contact");
            return false;
        }
        if (formvalue.account_holder_name == '' || formvalue.account_holder_name == null) {
            this.toast.error("Please enter Account Holder Name");
            return false;
        }
        if (formvalue.account_number == '' || formvalue.account_number == null) {
            this.toast.error("Please enter Account Number");
            return false;
        }
        if (formvalue.bank_name == '' || formvalue.bank_name == null) {
            this.toast.error("Please enter Bank Name");
            return false;
        }
        if (formvalue.ifsc_code == '' || formvalue.ifsc_code == null) {
            this.toast.error("Please enter Bank Name");
            return false;
        }
        if (formvalue.gender == '' || formvalue.gender == null) {
            this.toast.error("Please enter Gender");
            return false;
        }

        if (formvalue.city == '' || formvalue.city == null) {
            this.toast.error("Please enter City");
            return false;
        }

        if (formvalue.postal_code == '' || formvalue.postal_code == null) {
            this.toast.error("Please enter Postal Code");
            return false;
        }

        if (formvalue.address == '' || formvalue.address == null) {
            this.toast.error("Please enter Address");
            return false;
        }

        if (formvalue.permanent_address == '' || formvalue.permanent_address == null) {
            this.toast.error("Please enter Permanent Address");
            return false;
        }

        if (formvalue.employment_category == '' || formvalue.employment_category == null) {
            this.toast.error("Please enter Employment Category");
            return false;
        }

        if (formvalue.marital_status == '' || formvalue.marital_status == null) {
            this.toast.error("Please enter Marital Status");
            return false;
        }
        // if (formvalue.job_title == '' || formvalue.job_title == null) {
        //     this.toast.error("Please enter Job Title");
        //     return false;
        // }
        if (formvalue.doj == '' || formvalue.doj == null) {
            this.toast.error("Please enter Date of Joining");
            return false;
        }
        if (formvalue.dob == '' || formvalue.dob == null) {
            this.toast.error("Please enter Date of Birth");
            return false;
        }
        if (formvalue.physical_location == '' || formvalue.physical_location == null) {
            this.toast.error("Please enter Physical Location");
            return false;
        }
        if (formvalue.functional_designation == '' || formvalue.functional_designation == null) {
            this.toast.error("Please enter Functional Designation");
            return false;
        }

        if (formvalue.hiring_type == '' || formvalue.hiring_type == null) {
            this.toast.error("Please enter Hiring Type");
            return false;
        }

        if (formvalue.aadhar_no == '' || formvalue.aadhar_no == null) {
            this.toast.error("Please enter Adhaar Number");
            return false;
        }

        if (formvalue.pan_no == '' || formvalue.pan_no == null) {
            this.toast.error("Please enter Pan Number");
            return false;
        }

        if (formvalue.personal_email == '' || formvalue.personal_email == null) {
            this.toast.error("Please enter Personal Email");
            return false;
        }
        if (formvalue.email == '' || formvalue.email == null) {
            this.toast.error("Please enter Email");
            return false;
        }
        if (formvalue.manager == '' || formvalue.manager == null) {
            this.toast.error("Please enter Manager");
            return false;
        }

        if (formvalue.department == '' || formvalue.department == null) {
            this.toast.error("Please enter Department");
            return false;
        }

        if (formvalue.business == '' || formvalue.business == null) {
            this.toast.error("Please enter Business");
            return false;
        }

        if (formvalue.company == '' || formvalue.company == null) {
            this.toast.error("Please enter Company");
            return false;
        }

        if (formvalue.initials == '' || formvalue.initials == null) {
            this.toast.error("Please enter Initials");
            return false;
        }
        if (formvalue.fname == '' || formvalue.fname == null) {
            this.toast.error("Please enter First Name");
            return false;
        }
        else {
            return true;
        }
    }

    onSubmit() {
        this.professionalInfoForm.get('emp_id')?.enable();
        console.log(this.professionalInfoForm.value)
        // this.professionalInfoForm.value.company = this.professionalInfoForm.value.company.company_id;
        // this.professionalInfoForm.value.manager = this.professionalInfoForm.value.manager.id;
        this.professionalInfoForm.value.business = Number(this.professionalInfoForm.value.business);
        this.professionalInfoForm.value.department = Number(this.professionalInfoForm.value.department);
        this.professionalInfoForm.value.status = Number(this.professionalInfoForm.value.status);

        if (this.isValid() && this.checkSolarExperience() && this.checkTotalExperience()) {

            console.log(this.professionalInfoForm.value);
            let manager;
            manager = this.professionalInfoForm.value.manager;
            this.professionalInfoForm.value.manager = manager.id;
            let company;
            company = this.professionalInfoForm.value.company;
            this.professionalInfoForm.value.company = company.company_id;
            let params = {
                action: 'submit',
            }
            let dialogRef = this.dialog.open(ActionPopupComponent, {
                width: "300px",
                data: {
                    buttontext: "Submit",
                    desc: "Do you want to Submit?",
                },
            });
            const sub = dialogRef.componentInstance.option.subscribe((data) => {
                if (data) {
                    sub.unsubscribe();
                    if (data == "success") {
                        this.empOnboardingService.offrollAddEmp({
                            umail: this.umail, professionalDetails: this.professionalInfoForm.value
                        })
                            .subscribe((response: any) => {
                                this.toast.success('Yay! Employee Added Succesfully!!!');
                                setTimeout(() => {
                                    window.location.reload();
                                }, 200)

                                console.log(response);
                                (error: any) => {
                                    console.log(error);
                                    this.toast.error('Please check form and try again!!')
                                }
                            }, error => {
                                // Handling email/emp_id errors
                                console.log(error);
                                this.toast.error("Please check form and try again !!!")
                                // if (error === 'ACTIVE_EMAIL_EXISTS') {
                                //   this.emailErrorMessage = 'Active Employee with given email already exists';
                                // } else if (error === 'EMP_ID_EXISTS') {
                                //   this.empIdErrorMessage = 'Employee ID already exists';
                                // }  
                                // this.errorMessage = 'An unexpected error occurred.';
                            });
                    }
                }
            });
        }


    }
}

